import captureException from '../../../sentry/captureException'
import FotoscapesURL from '../fotoscape/FotoscapesURL'

import { IFotoscapesAdviceResponse } from './types/IFotoscapesAdvice'
import { IFotoscapesFortuneCookieResponse } from './types/IFotoscapesFortuneCookie'
import { IFotoscapesJokesResponse } from './types/IFotoscapesJokes'
import { IFotoscapesMemesResponse } from './types/IFotoscapesMemes'
import { fetchGetUrl } from '../../scripts/utilities/net'

class CarouselLoader {
  static items: any[] = []
  static loaded: any = {}

  static async fetch<T>(schedule: string, language: string) {
    const previewAspect = '1:1'
    const url =
      `${FotoscapesURL.getBaseURL()}/wp/v1/daily?ckey=${FotoscapesURL.getKey()}` +
      `&mp_lang=${language}` +
      `&sched=${schedule}` +
      `&previewAspect=${previewAspect}`

    const response = await fetchGetUrl(url)
    return response.data as T
  }

  static async load<T>(schedule: string, language: string) {
    if (this.loaded[schedule]) {
      return this.items[schedule] as T[]
    }

    switch (schedule) {
      case 'advice':
        let adviceResponse = await this.fetch<IFotoscapesAdviceResponse>(schedule, language)

        if (adviceResponse.items.length === 0 && language !== 'en') {
          adviceResponse = await this.fetch<IFotoscapesAdviceResponse>(schedule, 'en')
        }

        this.items[schedule] = adviceResponse.items
          .map(item => {
            try {
              // Yeah, fortunes and advice use the same API endpoint. --hrivera
              return item.fortune.fortunetext[language] || item.fortune.fortunetext.en
            } catch (e) {
              captureException(e, 'CarouselLoader.load: could not parse advice from response')
              return [] as T[]
            }
          })
          .filter(item => item)
        break
      case 'fortune-cookie':
        let fortuneResponse = await this.fetch<IFotoscapesFortuneCookieResponse>(schedule, language)

        if (fortuneResponse.items.length === 0 && language !== 'en') {
          fortuneResponse = await this.fetch<IFotoscapesFortuneCookieResponse>(schedule, 'en')
        }

        this.items[schedule] = fortuneResponse.items
          .map(item => {
            try {
              return item.fortune.fortunetext[language] || item.fortune.fortunetext.en
            } catch (e) {
              captureException(
                e,
                'CarouselLoader.load: could not parse fortune cookies from response'
              )
              return [] as T[]
            }
          })
          .filter(item => item)
        break
      case 'jokes':
        let jokesResponse = await this.fetch<IFotoscapesJokesResponse>(schedule, language)

        if (jokesResponse.items.length === 0 && language !== 'en') {
          jokesResponse = await this.fetch<IFotoscapesJokesResponse>(schedule, 'en')
        }

        this.items[schedule] = jokesResponse.items
          .map(item => {
            try {
              return {
                setup: item.joke.setup[language] || item.joke.setup.en,
                punchline: item.joke.punchline[language] || item.joke.punchline.en
              }
            } catch (e) {
              captureException(e, 'CarouselLoader.load: could not parse jokes from response')
              return [] as T[]
            }
          })
          .filter(item => item)
        break
      case 'memes':
        const memesResponse = await this.fetch<IFotoscapesMemesResponse>(schedule, language)
        this.items[schedule] = memesResponse.items.map(item => {
          try {
            return {
              punchline: item.meme.memetext[language] || item.meme.memetext.en,
              url: item.images[0].link,
              startDate: '07/14/21' // Hold over from static configuration.
            }
          } catch (e) {
            captureException(e, 'CarouselLoader.load: could not parse memes from response: ')
            return [] as T[]
          }
        })
        break
      default:
        throw new Error(`CarouselLoader.load: ${schedule} is not supported`)
    }

    this.loaded[schedule] = true

    return this.items[schedule] as T[]
  }
}

export default CarouselLoader
