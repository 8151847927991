export interface FetchOptions {
  method: string | undefined
  url: string
  data: any | undefined
  headers: any | undefined
  body: string | Buffer | undefined
}

export const fetchWithOptions = async (cfg: FetchOptions) => {
  const { method, url, data, headers, ...rest } = cfg

  const options = {
    method: method || 'GET',
    headers: headers || {},
    ...rest
  }

  if (data) {
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'
  }

  const response = await fetch(url, options)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  try {
    const json = await response.json()
    return {
      data: json,
      headers: response.headers
    }
  } catch (e) {
    throw e
  }
}

export const fetchGetUrl = async aUrl => {
  return await fetchWithOptions({ url: aUrl } as FetchOptions)
}

export const fetchPostUrlWithData = async (url, data) => {
  return await fetchWithOptions({
    url,
    data
  } as FetchOptions)
}
